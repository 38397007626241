<template>
  <v-sheet class="py-md-5" height="100%" color="transparent">
    <div class="text-center mb-5">
      <core-subheading class="mb-1"  v-text="value.name"/>
    </div>
    <v-row>
      <v-col v-if="value.src" class="hidden-lg-and-up">
        <v-img
          :src="require(`@/assets/publications/${value.src}`)"
          height="200"
          width="500"
          contain
          class="mx-auto"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <div class="mb-3" style="max-width: 500px;">
          <core-subheading class="mb-3">
            Summary
          </core-subheading>
          <core-text v-text="value.description" />
          <core-subheading>
            Publication Info
          </core-subheading>
          <v-list class="transparent">
            <v-list-item>
              <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">
                <v-icon color="primary">
                  mdi-book
                </v-icon>
              </v-list-item-action>
              <v-list-item-title class="mr-2">
                Publisher
              </v-list-item-title>
              <v-list-item-title v-text="value.publisher" class="caption grey--text text--lighten-1" />
            </v-list-item>
            <v-list-item>
              <v-list-item-action v-if="$vuetify.breakpoint.smAndUp">
                <v-icon color="primary">
                  mdi-teamviewer
                </v-icon>
              </v-list-item-action>
              <v-list-item-title class="mr-2">
                Date
              </v-list-item-title>
              <v-list-item-title v-text="value.date" class="caption grey--text text--lighten-1"/>
            </v-list-item>
          </v-list>
        </div>
      </v-col>
      <v-col v-if="value.src" class="hidden-md-and-down">
        <v-img :src="require(`@/assets/publications/${value.src}`)" height="350" width="500" contain class="mx-auto"/>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        name: '',
        src: undefined
      })
    }
  }
}
</script>
